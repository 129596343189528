import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { graphql } from 'relay-runtime';
import { useFragment } from 'react-relay/hooks';
import { ButtonLink, Icon, InfoSausage, Image, Heading, VerticalCard, CardMedia } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { Color, FontTextSize } from '@pafcloud/style';
import { rememberGameHomeUrl, useFormatAmount } from '@pafcloud/react-hook-utils';
import { TurnoverBonus } from '@pafcloud/game-components';
import type {
  MultiplayerBingoItem_bingoItem$data,
  MultiplayerBingoItem_bingoItem$key,
} from './__generated__/MultiplayerBingoItem_bingoItem.graphql';

const multiplayerBingoItemFragment = graphql`
  fragment MultiplayerBingoItem_bingoItem on MultiplayerBingoListItem {
    thumbnail
    participants
    startTime
    variantText
    variantIcon
    ballCount
    cardCost
    jackpots {
      jackpotId
      amount
    }
    game {
      name
      slug
      launchUrl
      ...TurnoverBonus_game
    }
  }
`;

const GameImage = styled(CardMedia)({
  marginBottom: -48,
});

const Content = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'flex-start',
  flexGrow: 1,
  padding: '16px 0',
  borderRadius: '0 0 var(--card-radius) var(--card-radius)',
  boxShadow: '0 -2px 4px 1px rgba(0,0,0,0.123)',
  zIndex: 1,
  background: 'var(--card-background)',
});

const GameTitle = styled(Heading)({
  margin: 0,
  color: Color.HeadingText,
  fontSize: FontTextSize.Huge,
  textAlign: 'center',
});

const Details = styled.div({
  display: 'grid',
  gridAutoFlow: 'column',
  justifyContent: 'center',
  gap: 8,
  width: '100%',
  padding: '8px 24px 16px',
  borderBottom: `1px solid ${Color.Surface.Base.Dimmed}`,
  color: Color.Surface.Base.Foreground,
  fontSize: FontTextSize.Tiny,
  fontWeight: 'bold',
});

const Detail = styled.div({
  display: 'grid',
  gridAutoFlow: 'column',
  gap: 4,
  background: Color.Surface.Base.Dimmed,
  borderRadius: '99vw',
  padding: '4px 8px',
});

const TicketSausage = styled(InfoSausage)({
  position: 'absolute',
  top: -4,
  left: '50%',
  transform: 'translate(-50%, -50%)',
  whiteSpace: 'nowrap',
});

const JackpotWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  width: '100%',
  gap: '8px 16px',
  padding: '16px 16px 0',
  fontSize: FontTextSize.Small,
  color: Color.BodyText,
});

const JackpotItem = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  lineHeight: 2,
});

const Action = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexBasis: '100%',
  alignSelf: 'center',
  justifyContent: 'flex-end',
});

const LaunchButton = styled(ButtonLink)({
  margin: '16px auto 0',
  minWidth: 168,
});

const ButtonText = styled.span({
  fontWeight: 'bold',
  textTransform: 'uppercase',
});

const ButtonTime = styled.span({
  color: 'inherit',
  opacity: 0.75,
  width: '3rem',
});

const MultiplayerBingoImage = styled(Image)({
  objectFit: 'cover',
  top: 0,
  left: 0,
  borderRadius: 'var(--card-radius) var(--card-radius) 0 0',
});

const BingoVerticalCard = styled(VerticalCard)({
  position: 'relative',
  overflow: 'unset',
});

type Jackpot = {
  readonly jackpotId: number;
  readonly amount: number;
};

type MultiplayerBingoItemProps = {
  bingoItem: MultiplayerBingoItem_bingoItem$key;
};

const Jackpots: FC<{ bingoItem: MultiplayerBingoItem_bingoItem$data }> = ({ bingoItem }) => {
  const firstJackpots = useMemo(() => bingoItem.jackpots?.slice(0, 3), [bingoItem.jackpots]);
  const secondJackpots = useMemo(() => bingoItem.jackpots?.slice(3, 6), [bingoItem.jackpots]);

  const formatAmount = useFormatAmount();

  const [shownJackpots, setShownJackpots] = useState<Jackpot[] | undefined>(firstJackpots);

  useEffect(() => {
    let intervalID: ReturnType<typeof setInterval>;
    if (secondJackpots?.length !== 0) {
      intervalID = setInterval(() => {
        setShownJackpots((currentJackpots) => {
          if (JSON.stringify(currentJackpots) === JSON.stringify(firstJackpots)) {
            return secondJackpots;
          } else {
            return firstJackpots;
          }
        });
      }, 4500);
    }
    return () => clearInterval(intervalID);
  }, [bingoItem, firstJackpots, secondJackpots]);

  return (
    <JackpotWrapper>
      {shownJackpots?.map((jackpot) => (
        <JackpotItem key={jackpot.jackpotId}>
          <Icon name="jackpot" size="1.2em" />
          <strong>
            {formatAmount(jackpot.amount, {
              decimals: false,
            })}
          </strong>
        </JackpotItem>
      ))}
    </JackpotWrapper>
  );
};

type PlayButtonProps = {
  startTime: string;
  launchUrl: string;
  children: string;
};

const PlayButton: FC<PlayButtonProps> = ({ startTime, launchUrl, children }) => {
  const [startCountdown, setStartCountdown] = useState({ seconds: 0, minutes: 0 });

  useEffect(() => {
    const intervalID = setInterval(() => {
      const distance = new Date(startTime).getTime() - Date.now();
      const totalSeconds = Math.floor(distance / 1000);
      const minutes = Math.max(Math.floor(totalSeconds / 60), 0);
      const seconds = Math.max(totalSeconds - minutes * 60, 0);

      setStartCountdown({ minutes, seconds });
    }, 1000);
    return () => clearInterval(intervalID);
  }, [startTime]);

  return (
    <LaunchButton
      size="default"
      variant="primary"
      href={launchUrl}
      aria-label={launchUrl}
      onClick={() => rememberGameHomeUrl()}
    >
      <ButtonText>{children}</ButtonText>
      <ButtonTime>
        {startCountdown.minutes.toString().length === 1 && '0'}
        {startCountdown.minutes}:{startCountdown.seconds.toString().length === 1 && '0'}
        {startCountdown.seconds}
      </ButtonTime>
    </LaunchButton>
  );
};

export const MultiplayerBingoItem: FC<MultiplayerBingoItemProps> = (props) => {
  const bingoItem = useFragment(multiplayerBingoItemFragment, props.bingoItem);
  const { t } = useTranslation('bingo');
  const formatAmount = useFormatAmount();

  return (
    <BingoVerticalCard>
      <TurnoverBonus game={bingoItem.game} isMultiplayer />
      <GameImage>
        {bingoItem.thumbnail ? (
          <MultiplayerBingoImage
            src={bingoItem.thumbnail}
            fill
            sizes="
            (max-width: 500px) 90vw,
            (max-width: 778px) 73vw,
            (max-width: 1174px) 35vw,
            (max-width: 1365px) 24vw,
            (max-width: 1810px) 20vw,
            (max-width: 1920px) 15vw,
            13vw"
          />
        ) : null}
      </GameImage>
      <Content>
        {bingoItem.variantText && (
          <TicketSausage icon={bingoItem.variantIcon ?? undefined} text={bingoItem.variantText} />
        )}
        <GameTitle>{bingoItem.game.name}</GameTitle>

        <Details>
          <Detail>
            <Icon name="account" size="1.2em" />
            {bingoItem.participants}
          </Detail>
          <Detail>
            <Icon name="coin" size="1.2em" />
            {formatAmount(bingoItem.cardCost)}
          </Detail>
          {bingoItem.ballCount && (
            <Detail>
              <Icon name="bingoBall" size="1.2em" />
              {bingoItem.ballCount}
            </Detail>
          )}
        </Details>

        <Jackpots bingoItem={bingoItem} />

        <Action>
          <PlayButton startTime={bingoItem.startTime} launchUrl={bingoItem.game.launchUrl}>
            {t('play')}
          </PlayButton>
        </Action>
      </Content>
    </BingoVerticalCard>
  );
};
