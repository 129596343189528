/**
 * @generated SignedSource<<680cbb649115afd0f7049ffed72c23a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultiplayerBingoItem_bingoItem$data = {
  readonly ballCount: number | null;
  readonly cardCost: number | null;
  readonly game: {
    readonly launchUrl: string;
    readonly name: string;
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"TurnoverBonus_game">;
  };
  readonly jackpots: ReadonlyArray<{
    readonly amount: number;
    readonly jackpotId: number;
  }> | null;
  readonly participants: number;
  readonly startTime: string;
  readonly thumbnail: string;
  readonly variantIcon: string | null;
  readonly variantText: string | null;
  readonly " $fragmentType": "MultiplayerBingoItem_bingoItem";
};
export type MultiplayerBingoItem_bingoItem$key = {
  readonly " $data"?: MultiplayerBingoItem_bingoItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"MultiplayerBingoItem_bingoItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MultiplayerBingoItem_bingoItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "participants",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "variantText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "variantIcon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ballCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cardCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MultiplayerBingoListItemJackpot",
      "kind": "LinkedField",
      "name": "jackpots",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jackpotId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Game",
      "kind": "LinkedField",
      "name": "game",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "launchUrl",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TurnoverBonus_game"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MultiplayerBingoListItem",
  "abstractKey": null
};

(node as any).hash = "4df01f67da51e9030a256a910b6676dc";

export default node;
